.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.knowledge-container {
  margin-top: 3.125vw;
  padding-left: 12.5vw;
  padding-right: 12.5vw;
}
.knowledge-container .main {
  display: flex;
}
.knowledge-container .main .news_left {
  width: 43.22916666666667vw;
}
.knowledge-container .main .news_left .info_null {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.knowledge-container .main .news_lis {
  width: 43.22916666666667vw;
  height: 10.416666666666668vw;
  margin-bottom: 3.177083333333333vw;
}
.knowledge-container .main .news_lis a {
  display: flex;
  text-decoration: none;
}
.knowledge-container .main .news_lis .news_lis_left {
  margin-right: 1.5625vw;
}
.knowledge-container .main .news_lis .news_lis_left img {
  width: 16.666666666666664vw;
  height: 10.416666666666668vw;
  overflow: hidden;
  border-radius: 0.8333333333333334vw;
  display: block;
  object-fit: cover;
}
.knowledge-container .main .news_lis .news_lis_right {
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.knowledge-container .main .news_lis .news_lis_right .title {
  font-size: 1.25vw;
  line-height: 1.7187500000000002vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.knowledge-container .main .news_lis .news_lis_right .title:hover {
  color: #2e6fed;
}
.knowledge-container .main .news_lis .news_lis_right .desc {
  font-size: 0.9375vw;
  line-height: 1.3541666666666667vw;
  margin-top: 0.5208333333333333vw;
  margin-bottom: 1.3541666666666667vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.knowledge-container .main .news_lis .news_lis_right .label {
  display: flex;
}
.knowledge-container .main .news_lis .news_lis_right .label .type,
.knowledge-container .main .news_lis .news_lis_right .label .time {
  display: flex;
  align-items: center;
}
.knowledge-container .main .news_lis .news_lis_right .label .type span,
.knowledge-container .main .news_lis .news_lis_right .label .time span {
  font-size: 0.9375vw;
  line-height: 1.3020833333333335vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.knowledge-container .main .news_lis .news_lis_right .label .time .img {
  width: 0.8333333333333334vw;
  height: 0.8333333333333334vw;
  margin-right: 5px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAVtJREFUOE+tk7FLw1AQxr9LqJOI6NI4uAh2cPCf0MXBSSc3kZKz2MVZQnEWoVIvFHFz0snBRf8JQQcLXRxMBhEpTobk5NWmtMFGKr7hwb3H/d73vbsjZFaz2VxKkmQHwKqqzptrInoGcGtZ1lm5XH4cTKE08DxvolgsHgHYAHBKRNdxHLfNvW3bC6q6DmAXwFUYhvu1Wu2zCzebSXYc50ZV36Mo2q5Wq52sMhPX6/WpQqFwTkTTQRCsGUgXICInABxm3gSgabKItJh5MQMjEbkEEDDzHhnPcRzfRVFUyr4sIsrMfZspqKfkybbtFfJ9/1hV35j5MCt7FKCn+oCIZgzgAcCW67r34wB8318GcGH8dJIkmatUKh/jABqNxqRlWS+/AV4BzA7Vnajlum6pD8iz8FMp07O+hbxPzAOIyPcn5pVxFGCojHmNNAIw3Ej/0sop5M/DNCh13HH+AkCc6FfGZNtqAAAAAElFTkSuQmCC") no-repeat;
  background-size: 100% 100%;
}
.knowledge-container .main .news_lis .news_lis_right .label .type:hover .img {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAL9JREFUOE9jZGBgYNDLf3uYgYHBBsTGDxgf/Gf85Xx5gsQ9mDpGqAH/L00UBrPxAb38t//+MzA8Zv7/1+XCJLHbILWkGvCfkYkp6f+/f03///53vTxF5AbJBoBcqp//LuY/w7+eSxNFJFAM0Mt/e4uBgUEV7g1GhluXJgirw/joYQUyjCQXIIePXv5bcLhR1wAML0CCGcUbSN6hgQsIpQOCYYDVC4gkhx4jg9ELFIcBJQYQmZ2RrGBkPHxpgpAdAGPBnxG7uG4kAAAAAElFTkSuQmCC);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 0.8333333333333334vw;
  height: 0.8333333333333334vw;
  margin-right: 5px;
}
.knowledge-container .main .news_lis .news_lis_right .label .type:hover .text {
  color: #2e6fed;
}
.knowledge-container .main .news_lis .news_lis_right .label .type {
  margin-right: 2.083333333333333vw;
}
.knowledge-container .main .news_lis .news_lis_right .label .type .img {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAALhJREFUOE/Nk8ENwjAMRb8zBQsglkBcgC04caijjMGZAeJ5ugQqEkJijiRVKoLSCghVQcInR3Ke/b8TAgARqQEsY16Iq3NubYy5pDq6AwIzd/m7EBEP4BZC2Gitz7F2LCAopfbe+wOALTOfRgPipNbaHREdmXnWA4hIA2CeyWiYeZHOQ68ibNQEuT8i0vn2XcATCbFpT0Ym5wcTlN5B0YMXEtK94Ub+UcJkD6YAPv3Ojx5EVFdVtWoBb0WbEaMq1UMAAAAASUVORK5CYII=);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 0.8333333333333334vw;
  height: 0.8333333333333334vw;
  margin-right: 5px;
}
.knowledge-container .main .categr_right {
  margin-left: 9.6875vw;
}
.knowledge-container .main .categr_right .knowledge_lis {
  box-sizing: border-box;
  width: 20.833333333333336vw;
  height: 14.895833333333334vw;
  padding-left: 1.5625vw;
  padding-top: 1.8229166666666667vw;
  padding-bottom: 2.604166666666667vw;
  padding-right: 5.3125vw;
  border-radius: 0.8333333333333334vw;
  background: #ffffff;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
}
.knowledge-container .main .categr_right .knowledge_lis .title {
  font-size: 1.25vw;
  line-height: 1.7187500000000002vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.knowledge-container .main .categr_right .knowledge_lis .lis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.knowledge-container .main .categr_right .knowledge_lis .lis .item {
  margin-top: 1.5625vw;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.knowledge-container .main .categr_right .knowledge_lis .lis .item img {
  width: 0.8333333333333334vw;
  height: 0.8854166666666666vw;
  margin-right: 0.4166666666666667vw;
}
.knowledge-container .main .categr_right .knowledge_lis .lis .item span {
  font-size: 0.9375vw;
  line-height: 1.3020833333333335vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.knowledge-container .main .categr_right .knowledge_lis .lis .item:hover span {
  color: #2e6fed !important;
}
.knowledge-container .main .categr_right .news_hot {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 24px 1px;
  box-sizing: border-box;
  width: 20.833333333333336vw;
  height: 21.25vw;
  border-radius: 0.5729166666666666vw;
  margin-top: 2.083333333333333vw;
  padding-top: 1.8229166666666667vw;
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
  padding-bottom: 3.125vw;
  background: url(https://mktv-in-cdn.mockuai.com/16347881491189021.png) no-repeat;
  background-size: contain;
}
.knowledge-container .main .categr_right .news_hot .title {
  font-size: 1.25vw;
  line-heiht: 1.7187500000000002vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff6600;
}
.knowledge-container .main .categr_right .news_hot .hot_main a {
  text-decoration: none;
  display: flex;
}
.knowledge-container .main .categr_right .news_hot .hot_main .hot_lis .left {
  width: 1.3020833333333335vw;
  height: 1.25vw;
  border-radius: 0.20833333333333334vw;
  font-size: 0.9375vw;
  line-height: 1.25vw;
  margin-right: 0.5208333333333333vw;
  background: #dddddd;
  text-align: center;
  font-family: DIN-MediumItalic, DIN;
  font-weight: normal;
  color: #ffffff;
}
.knowledge-container .main .categr_right .news_hot .hot_main .right {
  flex: 1;
  font-size: 0.9375vw !important;
  line-height: 1.1458333333333333vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.knowledge-container .main .categr_right .news_hot .hot_main :nth-child(2) .left {
  background: #ee6a0c;
}
.knowledge-container .main .categr_right .news_hot .hot_main :nth-child(3) .left {
  background: #f9ab0d;
}
.knowledge-container .main .categr_right .news_hot .hot_lis:nth-of-type(1) {
  margin-top: 2.03125vw;
}
.knowledge-container .main .categr_right .news_hot .hot_lis:nth-of-type(1) .left {
  background: red;
}
.knowledge-container .main .categr_right .news_hot .hot_lis {
  margin-bottom: 1.3020833333333335vw;
}
.knowledge-container .main .categr_right .news_hot .hot_lis :hover .right {
  color: #2e6fed;
}
.knowledge-container .main .label_list {
  box-sizing: border-box;
  width: 20.833333333333336vw;
  margin-top: 2.083333333333333vw;
  padding-top: 1.8229166666666667vw;
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
  padding-bottom: 3.125vw;
  border-radius: 0.8333333333333334vw;
  background: #ffffff;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
}
.knowledge-container .main .label_list .title {
  font-size: 1.25vw;
  line-height: 1.7187500000000002vw;
  margin-bottom: 1.3541666666666667vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.knowledge-container .main .label_list .label_box {
  display: flex;
  flex-wrap: wrap;
}
.knowledge-container .main .label_list .label_box .label_item {
  margin-right: 0.5208333333333333vw;
  margin-bottom: 0.5208333333333333vw;
  width: 5.208333333333334vw;
  text-align: center;
}
.knowledge-container .main .label_list .label_box a {
  text-decoration: none;
  font-size: 0.7291666666666666vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  width: 5.208333333333334vw;
  display: block;
  padding-top: 0.5208333333333333vw;
  padding-bottom: 0.5208333333333333vw;
  border-radius: 0.20833333333333334vw;
  background: #f7f7f7;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.knowledge-container .main .label_list .label_box .label_item :hover {
  color: #fff !important;
  background: #ff6600 !important;
}
.knowledge-container .pagination {
  text-align: center;
  margin-top: 3.3854166666666665vw;
  padding-bottom: 4.166666666666666vw;
}
.knowledge-container /deep/ .el-pagination.is-background .el-pager li:hover {
  color: #ff5f23;
}
.knowledge-container /deep/ .el-pagination.is-background .el-pager li.active {
  background: #ff6600;
  color: #fff;
}
.knowledge-container /deep/ button {
  width: 2.083333333333333vw;
  height: 2.083333333333333vw;
}
.knowledge-container /deep/ .el-pager .number {
  width: 2.083333333333333vw;
  height: 2.083333333333333vw;
  line-height: 2.083333333333333vw;
  font-size: 0.9375vw;
}
.knowledge-container /deep/ .el-icon {
  width: 2.083333333333333vw;
  height: 2.083333333333333vw;
  line-height: 2.083333333333333vw;
}
@media screen and (min-width: 1440px) {
  .pagination /deep/ button {
    width: 40px;
    height: 40px;
  }
  .pagination /deep/ .el-pager .number {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
  .pagination /deep/ .el-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
