.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.banner {
  height: 19.791666666666664vw;
  width: 100vw;
  padding-top: 150px;
  background-color: #00092a;
  background-image: url(https://mktv-in-cdn.mockuai.com/16347160810235773.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
}
.banner .title {
  margin: 0 auto;
}
.banner .title img {
  z-index: 22;
  display: block;
  margin: 0 auto;
  width: 20.208333333333332vw;
  height: 3.90625vw;
  margin-top: 7.968749999999999vw;
}
